var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-panel-overlay",
    attrs: {
      "id": "user-overlay"
    },
    on: {
      "click": _vm.closePanel
    }
  }, [_c('div', {
    staticClass: "user-panel"
  }, [_c('div', {
    staticClass: "development-panel-header",
    attrs: {
      "id": "userpanel-header"
    }
  }, [_c('v-row', [_c('h4', [_vm._v(_vm._s(_vm.building.buildingName ? _vm.building.buildingName : _vm.building.addressObjects[0].address))])]), _c('v-row', {
    staticStyle: {
      "display": "contents"
    }
  }, [_c('EditButton', {
    on: {
      "clicked": function ($event) {
        return _vm.editBuilding(_vm.building);
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "user-panel-body",
    attrs: {
      "id": "user-body"
    }
  }, [_c('div', {
    staticClass: "main-info-section"
  }, [_c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("Subblock.GeneralData")))]), _vm.building.buildingName ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PropertyName")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.building.buildingName))])]) : _vm._e(), _vm.building.addressObjects ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BuildingAddress")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.building.addressObjects[0].address))])]) : _vm._e(), _vm.building.constructionYear ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ConstructionYear")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.building.constructionYear)) + " ")])]) : _vm._e(), _vm.building.refurbishmentYear ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("RefurbishmentYear")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.building.refurbishmentYear)) + " ")])]) : _vm._e(), _vm.building.buildingCostCenter ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BuildingCostCenter")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.building.buildingCostCenter) + " ")])]) : _vm._e(), _vm.building.addOnFactor ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("AddOnFactor")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.building.addOnFactor))])]) : _vm._e()], 1), _c('div', {
    staticClass: "main-info-section",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("Features")))]), _vm.building.buildingHeatingType ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BuildingHeatingType")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.building.buildingHeatingType))])]) : _vm._e(), _vm.building.coolingType ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("CoolingType")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.building.coolingType) + " ")])]) : _vm._e(), _vm.building.buildingVentilationType ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BuildingVentilationType")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatVentilationType(_vm.building.buildingVentilationType)) + " ")])]) : _vm._e()], 1), _c('div', {
    staticClass: "main-info-section",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("Size")))]), _vm.building.netFloorArea ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("NetFloorArea")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.building.netFloorArea) + " m²")])]) : _vm._e(), _vm.building.totalArea ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("TotalArea")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.building.totalArea) + " m² ")])]) : _vm._e(), _vm.building.floorArea ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("FloorArea")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.building.floorArea) + " m² ")])]) : _vm._e(), _vm.building.grossLettableArea ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("GrossLettableArea")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.building.grossLettableArea) + " m² ")])]) : _vm._e()], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }