var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.development ? _c('div', {
    key: _vm.refresher,
    class: {
      'content left-panel-closed': !_vm.propertyDetailPanel,
      'content left-panel-open': _vm.propertyDetailPanel
    },
    attrs: {
      "id": "propertyDeveloper-content"
    }
  }, [_vm.activeView.value === 'prospects' || _vm.activeView.value === 'units_list' ? _c('BaseToolbar', {
    attrs: {
      "is-yellow": _vm.tableEdit || _vm.addRowProspects || _vm.editModeProspects
    }
  }, [_vm.activeView.value === 'units_list' ? _c('ToolbarContentUnits', {
    attrs: {
      "development": _vm.development,
      "table-edit": _vm.tableEdit,
      "selected-units": _vm.selectedUnits
    },
    on: {
      "openUnitFormAddButton": function ($event) {
        return _vm.openUnitFormAddButton();
      },
      "saveUnits": function ($event) {
        return _vm.saveUnits();
      },
      "editTable": function ($event) {
        _vm.tableEdit = !_vm.tableEdit;
      },
      "clearSelectedUnits": function ($event) {
        return _vm.clearSelectedUnits();
      },
      "openStatusDropdown": function ($event) {
        return _vm.openStatusDropdown();
      },
      "duplicateUnits": function ($event) {
        _vm.unitsToDuplicate = _vm.selectedUnits;
        _vm.timesToDuplicate = 1;
      },
      "deleteUnits": function ($event) {
        _vm.unitsToDelete = _vm.selectedUnits;
      }
    }
  }) : _vm._e(), _vm.activeView.value === 'prospects' ? _c('ToolbarContentProspects', {
    attrs: {
      "development": _vm.development
    },
    on: {
      "saveProspects": function ($event) {
        return _vm.saveProspects();
      },
      "saveAndAddAnotherProspect": function ($event) {
        return _vm.saveAndAddAnotherProspect();
      },
      "promoteToContact": function ($event) {
        return _vm.promoteToContact();
      },
      "deleteProspects": function ($event) {
        _vm.prospectsToDelete = _vm.selectedProspects;
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "content-wrapper"
  }, [_c('div', {
    staticClass: "panel-wrapper",
    attrs: {
      "id": "panel-wrapper-development"
    }
  }, [!_vm.development ? _c('div', {
    staticClass: "overlay"
  }, [_vm._m(0)]) : _vm._e(), _c('div', {
    staticClass: "table-col-wrapper"
  }, [_c('v-col', {
    key: _vm.buildingsRefresher,
    staticStyle: {
      "padding": "0"
    }
  }, [_c('div', {
    staticClass: "table-wrapper"
  }, [_vm.development.buildings.length > 0 && _vm.activeView.value === 'units_list' ? _c('DevelopmentBuildingsTable', {
    attrs: {
      "development-buildings": _vm.development.buildings,
      "development": _vm.development,
      "clear-selected-units": _vm.selectedUnitsClearer,
      "table-refresher": _vm.tableRefresher,
      "table-edit": _vm.tableEdit,
      "table-save": _vm.tableSave
    },
    on: {
      "addUnit": _vm.openUnitForm,
      "updateUnit": _vm.updateUnit,
      "refresh": _vm.refreshUnits,
      "updateUnits": _vm.updateUnits,
      "openLeadPanel": _vm.openLeadForm,
      "unitsSelected": function ($event) {
        _vm.selectedUnits = $event;
      },
      "updateUnitInterior": _vm.updateUnitInterior,
      "unitClicked": function ($event) {
        _vm.clickedUnit = $event;
        _vm.unitOpened = true;
      }
    }
  }) : _vm._e(), _vm.development.buildings.length > 0 && _vm.activeView.value === 'units_grid' ? _c('KanbanContent', {
    attrs: {
      "development": _vm.development
    },
    on: {
      "unitClicked": function ($event) {
        _vm.clickedUnit = $event;
        _vm.unitOpened = true;
      }
    }
  }) : _vm._e(), _vm.activeView.value === 'listings' ? _c('DevelopmentListings', {
    attrs: {
      "development": _vm.development
    },
    on: {
      "getDevelopment": function ($event) {
        return _vm.getDevelopment();
      }
    }
  }) : _vm._e(), _vm.activeView.value === 'prospects' ? _c('Prospects', {
    attrs: {
      "development": _vm.development
    }
  }) : _vm._e()], 1)])], 1)]), _vm.unitsToDuplicate.length === 1 ? _c('ConfirmationModal', {
    on: {
      "onCancel": function ($event) {
        _vm.unitsToDuplicate = [];
      },
      "onConfirm": function ($event) {
        return _vm.duplicateUnits(_vm.timesToDuplicate);
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "24px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "flex-wrap": "nowrap"
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "src": require(`@/assets/images/info.svg`),
      "alt": ""
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("PleaseConfirmDuplication") + " " + _vm.$t("Selected").toLowerCase() + " " + _vm.$t("Unit'").toLowerCase()) + " ")])]), _c('div', {
    staticClass: "duplication-count"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Duplicate")))]), _c('div', {
    staticStyle: {
      "width": "80px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.timesToDuplicate,
      callback: function ($$v) {
        _vm.timesToDuplicate = $$v;
      },
      expression: "timesToDuplicate"
    }
  })], 1), _c('p', [_vm._v(_vm._s(_vm.$t("Time(s)").toLowerCase()))])])])]) : _vm._e(), _vm.unitsToDuplicate.length > 1 ? _c('ConfirmationModal', {
    attrs: {
      "text-start-icon": 'info.svg'
    },
    on: {
      "onCancel": function ($event) {
        _vm.unitsToDuplicate = [];
      },
      "onConfirm": function ($event) {
        return _vm.duplicateUnits(1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("PleaseConfirmDuplication") + " " + _vm.unitsToDuplicate.length + " " + _vm.$t("Units'").toLowerCase()) + " ")]) : _vm._e(), _vm.unitsToDelete.length > 0 ? _c('ConfirmationModal', {
    attrs: {
      "text-start-icon": 'info.svg'
    },
    on: {
      "onCancel": function ($event) {
        _vm.unitsToDelete = [];
      },
      "onConfirm": _vm.deleteUnits
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("PleaseConfirmDelete") + " " + _vm.unitsToDelete.length + " " + _vm.$t("Units'").toLowerCase()) + " ")]) : _vm._e(), _vm.prospectsToDelete.length > 0 ? _c('ConfirmationModal', {
    attrs: {
      "text-start-icon": 'info.svg'
    },
    on: {
      "onCancel": function ($event) {
        _vm.prospectsToDelete = [];
      },
      "onConfirm": _vm.deleteProspects
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("PleaseConfirmRemove") + " " + _vm.prospectsToDelete.length + " " + _vm.$t("Prospects'").toLowerCase()) + " ")]) : _vm._e(), _vm.statusesOpen ? _c('StatusDropdown', {
    attrs: {
      "units-view": true,
      "position": _vm.position,
      "units": _vm.selectedUnits,
      "is-development": true,
      "developmentId": _vm.development._id,
      "multi": true
    },
    on: {
      "closed": function ($event) {
        _vm.statusesOpen = false;
      },
      "statusChanged": _vm.changeUnitStatusBulk,
      "open-booking-modal": function ($event) {
        return _vm.openBookingDetailsModal();
      }
    }
  }) : _vm._e(), _vm.bookingDetailsModalOpen ? _c('BookingDetailsModal', {
    attrs: {
      "is-unit": true,
      "bulk-booking": true
    },
    on: {
      "statusChanged": _vm.changeUnitStatusBulk,
      "close-modal": function ($event) {
        _vm.bookingDetailsModalOpen = false;
      }
    }
  }) : _vm._e(), _vm.detailPanelOpened ? _c('DevelopmentDetailPanel', {
    key: _vm.developmentUpdated,
    attrs: {
      "developmentId": _vm.development._id
    },
    on: {
      "overlayClicked": _vm.toggleDetailPanel,
      "editDevelopment": _vm.editDevelopment
    }
  }) : _vm._e(), _vm.editDevelopmentPanelOpen ? _c('AddDevelopmentSidepanel', {
    attrs: {
      "developmentData": _vm.development,
      "from-another-panel": _vm.editPanelNested,
      "isEdit": true
    },
    on: {
      "closed": function ($event) {
        return _vm.closeSidepanelAdd();
      }
    }
  }) : _vm._e(), _vm.buildingPanelOpened ? _c('BuildingSidepanelAdd', {
    attrs: {
      "development": _vm.development
    },
    on: {
      "closed": function ($event) {
        return _vm.toggleBuildingPanel();
      },
      "getDevelopment": function ($event) {
        return _vm.getDevelopment();
      }
    }
  }) : _vm._e(), _vm.buildingDetailPanelOpen ? _c('BuildingDetailPanel', {
    attrs: {
      "building": _vm.clickedBuilding
    },
    on: {
      "overlayClicked": _vm.toggleBuildingDetailPanel,
      "editBuilding": _vm.toggleBuildingEditPanel
    }
  }) : _vm._e(), _vm.editBuildingPanelOpen ? _c('BuildingSidepanelAdd', {
    attrs: {
      "development": _vm.development,
      "buildingData": _vm.clickedBuilding,
      "isEdit": true
    },
    on: {
      "closed": function ($event) {
        return _vm.toggleBuildingEditPanel();
      },
      "getDevelopment": function ($event) {
        return _vm.getDevelopment();
      }
    }
  }) : _vm._e(), _vm.unitFormOpened ? _c('BuildingUnitsAdd', {
    attrs: {
      "development-id": _vm.development._id,
      "development": _vm.development,
      "building-id": _vm.buildingId
    },
    on: {
      "saved": _vm.unitsAdded,
      "closed": function ($event) {
        return _vm.closeUnitForm();
      }
    }
  }) : _vm._e(), _vm.unitFormOpenedAddButton ? _c('BuildingUnitsAdd', {
    attrs: {
      "development": _vm.development,
      "select-building": true,
      "development-id": _vm.development._id,
      "building-id": _vm.buildingId,
      "buildings": _vm.development.buildings
    },
    on: {
      "saved": _vm.unitsAdded,
      "closed": function ($event) {
        return _vm.unitFormClosedAddButton();
      }
    }
  }) : _vm._e(), _vm.undoButton ? _c('UndoButton', {
    attrs: {
      "units-view": false,
      "building-to-undo": _vm.recentlyAddedBuildingId,
      "development-to-undo": _vm.development._id,
      "items-to-undo": _vm.recentlyAddedItems
    },
    on: {
      "timerDone": _vm.handleTimerDone,
      "undone": function ($event) {
        _vm.undoButton = false;
        _vm.$router.go(0);
      }
    }
  }) : _vm._e(), _vm.leadFormOpened ? _c('LeadsSidepanel', {
    attrs: {
      "unitId": _vm.unitId,
      "developmentId": _vm.development._id,
      "buildingId": _vm.buildingId,
      "unit": _vm.unitItem,
      "building": _vm.buildingItem
    },
    on: {
      "overlayClicked": function ($event) {
        _vm.closeLeadForm();
        _vm.getDevelopment();
      }
    }
  }) : _vm._e(), _vm.interiorPackageOpened ? _c('InteriorPanel', {
    attrs: {
      "development": _vm.development,
      "existing-interiors": _vm.development.interiorPackages
    },
    on: {
      "refresher": function ($event) {
        return _vm.getDevelopment();
      },
      "closed": function ($event) {
        return _vm.toggleInteriorPackagesPanel();
      },
      "interiorAdded": function ($event) {
        return _vm.getDevelopment();
      }
    }
  }) : _vm._e(), _vm.unitOpened ? _c('UnitSidepanel', {
    attrs: {
      "unit-id": _vm.clickedUnit._id
    },
    on: {
      "closed": function ($event) {
        _vm.unitOpened = !_vm.unitOpened;
      },
      "update": function ($event) {
        return _vm.getDevelopment();
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay__inner"
  }, [_c('div', {
    staticClass: "overlay__content"
  }, [_c('span', {
    staticClass: "spinner"
  })])]);

}]

export { render, staticRenderFns }