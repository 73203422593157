<template>
  <div class="overlay" id="overlay-project">
    <div id="panel-wrapper-project" class="panel-wrapper">
      <div v-if="loadingSave" class="loader-overlay">
        <div class="loader units-add-loader"></div>
      </div>
      <div id="content-wrapper">
        <div
          v-bind:class="{
            'sticky-header': development.assetClass !== 'land',
            'relative-header': development.assetClass === 'land',
          }"
        >
       
          <v-row
            class="top-row"
            style="height: 24px; padding-left: 32px; padding-right: 32px"
            v-bind:class="{
              'top-row': development.assetClass !== 'land',
              'top-row pd-bottom-32': development.assetClass === 'land',
            }"
            ><h4 v-if="development.assetClass === 'land'" style="height: 24px">
              {{ $t("AddPlot") }}
            </h4>
            <h4 v-else style="height: 24px">
              {{ $t("AddUnits") }}
            </h4>
          </v-row>
          <v-row
            v-if="development.assetClass !== 'land'"
            style="
              width: 100%;
              height: 42px;
              margin-top: 32px;
              padding-left: 32px;
            "
          >
            <div
              v-bind:class="{
                'active-view': activeView === 'single',
                '': activeView !== 'single',
              }"
              @click="changeToSingle"
              id="singleView"
            >
              <p
                v-if="activeView === 'single'"
                class="content-small view-link"
                style="font-weight: 600"
              >
                {{ $t("AddSingleUnit") }}
              </p>
              <p
                v-if="activeView !== 'single'"
                class="content-small view-link"
                style="font-weight: 400"
              >
                {{ $t("AddSingleUnit") }}
              </p>
            </div>
            <div
              v-bind:class="{
                'active-view': activeView === 'bulk',
                '': activeView !== 'bulk',
              }"
              @click="changeToBulk"
              id="bulkView"
            >
              <p
                v-if="activeView === 'bulk'"
                class="content-small view-link"
                style="font-weight: 600"
              >
                {{ $t("AddBulk") }}
              </p>
              <p
                v-if="activeView !== 'bulk'"
                class="content-small view-link"
                style="font-weight: 400"
              >
                {{ $t("AddBulk") }}
              </p>
            </div>
          </v-row>
          <div
            style="border: 0.1px solid #e6e8ec; width: 560px"
            class="separator-row-main"
          ></div>
        </div>
        <div v-if="activeView === 'single'">
          <v-row
            v-bind:class="{
              'unit-details': development.assetClass !== 'land',
              'unit-details-land': development.assetClass === 'land',
            }"
            ><h5 v-if="development.assetClass === 'land'">
              {{ $t("PlotDetails") }}
            </h5>
            <h5 v-else>{{ $t("UnitDetails") }}</h5></v-row
          >
          <v-row
            style="
              margin-top: 24px;
              padding-left: 32px;
              padding-right: 32px;
              gap: 12px;
            "
          >
            <AddressFinder
              v-if="development.assetClass === 'land'"
              :object="form.objects ? form.objects[0] : ''"
              :placeholder="$t('Address')"
              :show-error="showErrors && !isFieldValid('addressObject')"
              :error-message="
                errors && errors.addressObject ? errors.addressObject : ''
              "
              @setObject="setAddressObject"
              @loaded="prefillAddress"
              @removeObject="removeAddressObject"
            />
            <BaseDropdown
              v-if="selectBuilding"
              v-show="development.assetClass !== 'land'"
              v-model="form.selectedBuilding"
              :items="buildingItems"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('SelectBuilding')"
              :type="'dropdown'"
              required="true"
              :error="
                showErrors && !isFieldValid('selectedBuilding')
                  ? 'not-valid'
                  : ''
              "
              :error-message="$t('FormErrors.' + errors.selectedBuilding)"
            />

            <BaseInput
              v-if="development.assetClass !== 'land'"
              :placeholder="$t('Floor')"
              v-model="form.floor"
              type="number"
            />
            <BaseInput
              v-if="development.assetClass === 'apartment'"
              :placeholder="$t('ApartmentNumber')"
              v-model="form.apartmentNumber"
              type="text"
            />
          </v-row>
          <v-row
            class="field-row"
            style="
              margin-top: 14px !important;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <BaseInput
              :placeholder="$t('UnitSize')"
              v-model="form.unitSize"
              type="number"
              :icon-right="'m2_20.svg'"
            />
          </v-row>
          <v-row
            class="field-row"
            style="
              margin-top: 12px !important;
              padding-left: 32px;
              padding-right: 32px;
              gap: 12px;
            "
          >
            <BaseInput
              :placeholder="$t('Price')"
              v-model="form.price"
              type="number"
              :icon-right="'euroSign.svg'"
            />
            <BaseInput
              v-if="development.assetClass !== 'land'"
              :placeholder="$t('RoomCount')"
              v-model="form.rooms"
              type="number"
            />
            <BaseDropdown
              v-model="form.status"
              :items="statuses"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('Status')"
              :type="'dropdown'"
            />
          </v-row>

          <div class="buttons-container">
            <v-divider></v-divider>
            <div class="buttons-row">
              <button @click="closeModal()" class="cancel">
                {{ $t("Cancel") }}
              </button>

              <button class="add-button" @click="saveUnits(form)">
                {{ $t("Add") }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="activeView === 'bulk'">
          <v-row
            style="padding-top: 168px; padding-left: 32px; padding-right: 32px"
            ><h5>{{ $t("UnitDetails") }}</h5>
          </v-row>
          <v-row
            style="
              margin-top: 14px !important;
              padding-left: 32px;
              padding-right: 32px;
              gap: 12px;
            "
          >
            <BaseDropdown
              v-if="selectBuilding"
              v-model="formBulk.selectedBuilding"
              :items="buildingItems"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('SelectBuilding')"
              :type="'dropdown'"
              required="true"
              :error="
                showBulkErrors && !isFieldValidBulk('selectedBuilding')
                  ? 'not-valid'
                  : ''
              "
              :error-message="$t('FormErrors.' + bulkErrors.selectedBuilding)"
            />
            <v-row style="flex-wrap: nowrap; gap: 12px">
              <BaseInput
                :placeholder="$t('FirstFloor')"
                v-model="formBulk.floorFrom"
                type="number"
                :required="true"
                :error="
                  showBulkErrors && !isFieldValidBulk('floorFrom')
                    ? 'not-valid'
                    : ''
                "
                :error-message="$t('FormErrors.' + bulkErrors.floorFrom)"
              />
              <BaseInput
                :placeholder="$t('LastFloor')"
                v-model="formBulk.floorTo"
                type="number"
                :required="true"
                :error="
                  showBulkErrors && !isFieldValidBulk('floorTo')
                    ? 'not-valid'
                    : ''
                "
                :error-message="$t('FormErrors.' + bulkErrors.floorTo)"
              />
            </v-row>
            <v-row style="margin-top: 0px; flex-wrap: nowrap; gap: 12px">
            <v-row style="width: 50%">
              <BaseInput
                :placeholder="$t('UnitsPerFloor')"
                v-model="formBulk.unitsPerFloor"
                type="number"
                :required="true"
                :error="
                  showBulkErrors && !isFieldValidBulk('unitsPerFloor')
                    ? 'not-valid'
                    : ''
                "
                :error-message="$t('FormErrors.' + bulkErrors.unitsPerFloor)"
              />
            </v-row>
            <v-row style="margin-top: 0px; width: 50%">
               <BaseInput
                :placeholder="$t('UnitSize')"
                v-model="formBulk.unitSize"
                type="number"
                :icon-right="'m2_20.svg'"
              />
            
            </v-row>
            </v-row>
            <v-row style="margin-top: 0px; flex-wrap: nowrap; gap: 12px">

            <v-row style="margin-top: 0px; width: 50%">
              <BaseInput
                :placeholder="$t('Price')"
                v-model="formBulk.price"
                type="number"
                :icon-right="'euroSign.svg'"
              />
            </v-row>
            <v-row style="margin-top: 0px; width: 50%">
              <BaseInput
                :placeholder="$t('Rooms')"
                v-model="formBulk.rooms"
                type="number"
              />
            </v-row>
            </v-row>
            <BaseDropdown
              v-model="formBulk.status"
              :items="statuses"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('Status')"
              :type="'dropdown'"
            />
          </v-row>
          <div class="buttons-container">
            <v-divider></v-divider>

            <div class="buttons-row">
              <button @click="closeModal()" class="cancel">
                {{ $t("Cancel") }}
              </button>

              <button class="add-button" @click="saveUnits">
                {{ $t("Add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import BaseInput from "@/components/common/BaseInput";
import BaseDropdown from "@/components/common/BaseDropdown";
import AddressFinder from "@/components/Deals/AddressFinder.vue";

export default {
  components: { BaseInput, BaseDropdown, AddressFinder },
  name: "UnitsAdd",
  props: [
    "object",
    "clients",
    "developmentId",
    "buildingId",
    "selectBuilding",
    "buildings",
    "development",
  ],

  data() {
    return {
      loadingSave: false,
      showErrors: false,
      showBulkErrors: false,
      validate: ["selectedBuilding"],
      validateBulk: [
        "floorFrom",
        "floorTo",
        "unitsPerFloor",
        "selectedBuilding",
      ],
      errors: null,
      bulkErrors: null,
      activeView: "single",
      menu: false,
      isErrorObject: false,
      isErrorFloor: false,
      isErrorFloorFrom: false,
      isErrorFloorTo: false,
      status: null,
      buildingItems: [],
      timeouts: [],
      addressObject: null,
      form: {
        name: "",
        floor: null, //mandatory
        objects: [],
        unitSize: null,
        leads: [],
        selectedBuilding: null,
        price: null,
        apartmentNumber: null,
        rooms: null,
        status: "free",
        bookedUntil: null,
        interior: null,
      },
      formBulk: {
        floorFrom: null, //mandatory
        floorTo: null, //mandatory
        unitsPerFloor: null, //mandatory
        unitSize: null,
        leads: [],
        selectedBuilding: null,
        price: null,
        rooms: null,
        status: "free",
        bookedUntil: null,
        interior: null,
      },
    };
  },
  created() {
    if (this.buildings) {
      this.parseBuildings();
    }
  },
  mounted() {
    window.onclick = (e) => {
      if (e.target.classList[0] === "overlay") {
        this.closeModal();
        e.target.style.display = "none";
      }
    };
    this.errors = this.getEmptyErrors();
    this.bulkErrors = this.getEmptyBulkErrors();
  },
  computed: {
    statuses() {
      if (this.development.assetType === "LeaseOut") {
        return [
          { name: this.$t("Free"), value: "free" },
          { name: this.$t("Booked"), value: "booked" },
          { name: this.$t("Rented"), value: "rented" },
        ];
      }
      return [
        { name: this.$t("Free"), value: "free" },
        { name: this.$t("Booked"), value: "booked" },
        { name: this.$t("Sold"), value: "sold" },
      ];
    },
  },
  watch: {},
  methods: {
    getEmptyErrors() {
      if (this.selectBuilding) {
        return {
          floor: "",
          selectedBuilding: "",
          addressObject: "",
        };
      } else {
        return {
          floor: "",
        };
      }
    },
    prefillAddress() {
      console.log("laetud:");
      this.form.objects[0] = this.buildings[0].addressObjects[0];
      this.setAddressObject(this.form.objects[0]);
      console.log("laetud:", this.form.objects[0]);
    },
    setAddressObject(object) {
      this.form.objects = [];
      this.addressObject = object;
      this.form.objects.push(this.addressObject);
      console.log("kaks: ", this.form.objects);
    },
    removeAddressObject() {
      this.addressObject = null;
      this.form.objects.pop();
    },
    parseBuildings() {
      this.buildings.forEach((building) => {
        if (building.buildingName) {
          this.buildingItems.push({
            name: building.buildingName,
            value: building._id,
          });
        } else {
          this.buildingItems.push({
            name: building.addressObjects[0].address,
            value: building._id,
          });
        }
      });
      if (this.development.assetClass === "land") {
        this.form.selectedBuilding = this.buildings[0]._id;
      }
      if (this.buildingItems.length === 0) {
        this.toastError(this.$t("CantAddUnitPleaseAddBuildingBefore"));
        this.$emit("closed");
      }
    },
    getEmptyBulkErrors() {
      if (this.selectBuilding) {
        return {
          floorFrom: "",
          floorTo: "",
          unitsPerFloor: "",
          selectedBuilding: "",
        };
      } else {
        return {
          floorFrom: "",
          floorTo: "",
          unitsPerFloor: "",
        };
      }
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();
      return this.validate
        .map((field) => {
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFormValidBulk() {
      this.bulkErrors = this.getEmptyBulkErrors();
      return this.validateBulk
        .map((field) => {
          return this.isFieldValidBulk(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      if (this.development.assetClass !== "land") {
        if (this.selectBuilding) {
          switch (fieldName) {
            case "floor":
              if (!this.form.floor) {
                this.errors.floor = "FieldRequired";
                return false;
              }
              break;
            case "selectedBuilding":
              if (!this.form.selectedBuilding) {
                this.errors.selectedBuilding = "FieldRequired";
                return false;
              }
              break;
          }
          return true;
        } else {
          switch (fieldName) {
            case "floor":
              if (!this.form.floor) {
                this.errors.floor = "FieldRequired";
                return false;
              }
              break;
          }
          return true;
        }
      }
      return true;
    },
    isFieldValidBulk(fieldName) {
      if (this.selectBuilding) {
        switch (fieldName) {
          case "floorFrom":
            if (!this.formBulk.floorFrom) {
              this.bulkErrors.floorFrom = "FieldRequired";
              return false;
            }
            break;
          case "floorTo":
            if (!this.formBulk.floorTo) {
              this.bulkErrors.floorTo = "FieldRequired";
              return false;
            }
            break;
          case "unitsPerFloor":
            if (!this.formBulk.unitsPerFloor) {
              this.bulkErrors.unitsPerFloor = "FieldRequired";
              return false;
            }
            break;
          case "selectedBuilding":
            if (!this.formBulk.selectedBuilding) {
              this.bulkErrors.selectedBuilding = "FieldRequired";
              return false;
            }
            break;
        }
        return true;
      } else {
        switch (fieldName) {
          case "floorFrom":
            if (!this.formBulk.floorFrom) {
              this.bulkErrors.floorFrom = "FieldRequired";
              return false;
            }
            break;
          case "floorTo":
            if (!this.formBulk.floorTo) {
              this.bulkErrors.floorTo = "FieldRequired";
              return false;
            }
            break;
          case "unitsPerFloor":
            if (!this.formBulk.unitsPerFloor) {
              this.bulkErrors.unitsPerFloor = "FieldRequired";
              return false;
            }
            break;
        }
        return true;
      }
    },
    changeToSingle() {
      this.activeView = "single";
      this.form.selectedBuilding = this.formBulk.selectedBuilding
        ? this.formBulk.selectedBuilding
        : null;
    },
    changeToBulk() {
      this.activeView = "bulk";
      this.formBulk.selectedBuilding = this.form.selectedBuilding
        ? this.form.selectedBuilding
        : null;
    },
    closeModal() {
      this.$emit("closed");
    },
    containsWord(str, word) {
      return str.match(new RegExp("\\b" + word + "\\b")) != null;
    },

    async saveUnits() {
      // SINGLE ADD
      this.showErrors = false;
      this.showBulkErrors = false;
      let activeView = this.activeView;
      if (activeView === "single") {
        console.log(this.form);
        if (!this.isFormValid()) {
          this.showErrors = true;
          console.log("jamad");
          return;
        }
        let developmentId = this.developmentId;
        if (this.buildingId) {
          let buildingId = this.buildingId;
          let body = { unit: this.form, buildingId };
          await axios.post(`/api/development/unit/add/${developmentId}`, body);
        } else {
          let body = {
            unit: this.form,
            buildingId: this.form.selectedBuilding,
          };
          await axios.post(`/api/development/unit/add/${developmentId}`, body);
        }
        this.$emit("saved");

        // BULK ADD
      } else if (activeView === "bulk") {
        if (!this.isFormValidBulk()) {
          this.showBulkErrors = true;
          return;
        }
        let totalUnits =
          (parseInt(this.formBulk.floorTo) -
            parseInt(this.formBulk.floorFrom)) *
          parseInt(this.formBulk.unitsPerFloor);
        if (totalUnits > 50) {
          alert("OBJECT UNITS LIMIT 50 ");
          return;
        }
        let bulkUnit = Object.assign({}, this.formBulk);
        let units = [];
        for (
          let i = parseInt(bulkUnit.floorFrom);
          i <= parseInt(bulkUnit.floorTo);
          i++
        ) {
          i = parseInt(i);
          let oneUnit = Object.assign({}, bulkUnit);

          oneUnit.floor = i;

          for (let i = 0; i < bulkUnit.unitsPerFloor; i++) {
            units.push(oneUnit);
          }
        }
        let developmentId = this.developmentId;
        let buildingId = null;
        if (this.buildingId) {
          buildingId = this.buildingId;
        } else {
          buildingId = this.formBulk.selectedBuilding;
        }
        //let buildingId = this.buildingId;
        let body = { units, buildingId };
        this.loadingSave = true;
        const saveResp = await axios.post(
          `/api/development/units/add/${developmentId}`,
          body
        );
        let saveRespData = saveResp.data;
        let savedUnitsIds = saveRespData.addedUnits;
        let savedBody = { units, buildingId, savedUnitsIds };
        this.loadingSave = false;
        this.$emit("saved", 1, savedBody);
        location.reload();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#wrapped-header-project {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

.v-text-field--outlined ::v-deep fieldset {
  border: 1px solid #e6e8ec;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 16px 64px;
  background-color: white;
  width: 526px;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-weight: 400;
    line-height: 16px;
  }
}

.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
}

.pipeline-header {
  margin-top: 48px;
  width: 30%;

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
}

.pipelineFields {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
  padding-top: 24px;
  padding-bottom: 48px;
}

.pipelineSelector {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  width: 100%;
  display: flex;
}

.pipelineSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.arrowLabel {
  width: 173px;
  cursor: pointer;
  height: 40px;
  display: flex;
  position: relative;
  font-size: 12px;
  background: #e6e8ec;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pipelineSelector > input:checked + label:before {
  border-left-color: #75787a;
}

.arrowLabel:before {
  right: -10px;
  z-index: 2;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 8px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 49.2%;
  height: 56px;
  padding-top: 0px !important;
  padding: 0px !important;
  padding-bottom: 0px !important;

  &:focus {
    outline: none !important;
  }
}

.arrowLabel:before {
  width: 0;
  bottom: 0;
  height: 0;
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-left: 10px solid #e6e8ec;
  border-bottom: 20px solid transparent;
}

.pipelineSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 101;
}

.panel-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  //padding-left: 32px;
  //padding-top: 48px;
  //padding-right: 32px;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  overflow-y: scroll;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

#panel-wrapper-project {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

.row {
  gap: 24px;
}

::v-deep .inaadressSearch,
::v-deep .ui-input-search {
  ul {
    //border: 1px solid #e6e8ec !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: white !important;
  }

  input {
    width: 80% !important;
    padding: 8px 12px;
    height: 52px;

    &:focus-visible {
      outline: none;
      border: none;
      //background-color: white !important;
    }

    &::after {
      content: url("../../assets/images/Dollar.svg");
    }
  }
}

::v-deep .ui-input-clear,
::v-deep .inAdsCopyright {
  display: none;
}

::v-deep #InAadressDiv {
  z-index: 5;
}

::v-deep .ui-filterable {
  border: 1px solid #e6e8ec !important;
  border-radius: 8px;
  background: white;

  &:focus-visible {
    border: none;
    outline: none;
  }
}

.no-active2 {
  width: 256px !important;
  max-height: 52px;
  z-index: 110;
}

.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  text-transform: none;
  border-radius: 8px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.divider {
  width: 100%;
  border: 1px solid #e6e8ec;
  margin-bottom: 16px;
  margin-top: 24px;
  left: 0;
  right: 0;
}

.no-active {
  width: 256px !important;
  max-height: 52px;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  width: 558px;
}

.relative-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: relative;
  background: white;
  width: 558px;
}

.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.no-active.v-input.v-input__slot {
  border-radius: 20px;
  border: 1px solid #e6e8ec !important;
}

.active-box {
  border: 1px solid #ff5c01;
  background: #ff5c01;
}

.v-text-field--outlined > fieldset {
  border: 1px solid #e6e8ec;
}

.probability {
  padding-right: 20%;
}

.field-row {
  margin-top: 24px !important;
}

.address-selected {
  padding-top: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;

  /* black */

  color: #000000;
}

.pipeline-name {
  display: none;
  white-space: nowrap;
  margin-left: 5px;
}

.chosen {
  background-color: #f4f5f7;
}

.pipeline-middle {
  display: flex;
  flex-direction: row;
  flex: 0;
  align-items: center;
  width: 56px;
  height: 40px;
  padding-right: 0px;
  background-color: #e6e8ec;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.pipeline-image-front {
  width: 12px;

  path {
    fill: #e6e8ec !important;
  }
}

.path-colored {
  path {
    fill: #fff0d4 !important;
  }
}

.path-selected {
  path {
    fill: #000000 !important;
  }
}

.delete-button {
  margin-right: 244px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
}

.pipeline-left {
  position: absolute;
  left: -1px;
}

.buttons-container {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 110;
  position: fixed;
  background: white;
  bottom: 0;
  height: 112px;
  width: 558px;
}

::v-deep .ui-input-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &:after {
    margin-right: 12px;
    margin-top: 6px;
    content: url("../../assets/images/search.svg");
  }
}

.pipeline-right {
  position: absolute;
  right: -1px;
}

.chosen-step-nr {
  color: white !important;
}

.chosen-step-name {
  display: initial;
  color: white !important;
}

.chosen-step-container {
  background-color: black !important;
  width: fit-content;
  padding-right: 20px;
}

.hover-step-container {
  background-color: #fff0d4;
  width: fit-content;
  padding-right: 20px;
}

//Unscrolling
.modal-open {
  overflow-y: hidden;
}

#singleView {
  padding-bottom: 22px;
}

#bulkView {
  padding-bottom: 22px;
}

#singleView,
#bulkView {
  cursor: pointer;
}

.active-view {
  border-bottom: 2px solid black;
}

.loader-overlay {
  right: 0;
  top: 0;
  width: 600px;
  bottom: 0;
  background-color: black;
  opacity: 0.1;
  position: fixed;
  z-index: 999;
  padding: inherit;
}

.units-add-loader {
  margin-top: 32px;
  position: fixed;
  top: 50%;
  right: 300px;
  z-index: 102;
}

::v-deep .theme--light.v-select .v-select__selections {
  color: #000000;
  line-height: 20px;
}

.unit-details {
  padding-top: 168px;
  padding-left: 32px;
  padding-right: 32px;
}

.unit-details-land {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
}
</style>
