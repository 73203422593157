var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-development"
    }
  }, [_c('div', {
    staticClass: "modal-wrapper",
    attrs: {
      "id": "panel-wrapper-development"
    }
  }, [_c('div', {
    staticClass: "content-overlay",
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header",
    staticStyle: {
      "z-index": "126"
    }
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [!_vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddNewBuilding")) + " ")]) : _vm._e(), _vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ChangeNewBuilding")) + " ")]) : _vm._e()])], 1), _c('v-divider', {
    staticStyle: {
      "top": "97px",
      "z-index": "126",
      "position": "fixed",
      "width": "560px"
    }
  }), _c('div', {
    staticStyle: {
      "padding": "121px 0px"
    }
  }, [!_vm.isEdit ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("GeneralInformation")))])]) : _vm._e(), !_vm.isEdit ? _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'development-building-name-field',
      "placeholder": _vm.$t('BuildingName')
    },
    model: {
      value: _vm.form.buildingName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingName", $$v);
      },
      expression: "form.buildingName"
    }
  }), !_vm.isEdit ? _c('AddressFinder', {
    attrs: {
      "object": _vm.form.addressObjects ? _vm.form.addressObjects[0] : '',
      "placeholder": _vm.$t('Address'),
      "show-error": _vm.showErrors && !_vm.isFieldValid('addressObject'),
      "error-message": _vm.errors && _vm.errors.addressObject ? _vm.errors.addressObject : '',
      "disabled": _vm.isEdit
    },
    on: {
      "setObject": _vm.setAddressObject,
      "loaded": _vm.prefillAddress,
      "removeObject": _vm.removeAddressObject
    }
  }) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "modal-footer"
  }, [_c('v-divider', {
    staticStyle: {
      "z-index": "111",
      "position": "fixed !important",
      "width": "560px"
    }
  }), _c('div', {
    staticClass: "buttons-row",
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_vm.isEdit ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.isDeleteModalOpen = true;
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('button', {
    staticClass: "save-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.updateBuilding();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "save-button",
    on: {
      "click": function ($event) {
        return _vm.saveBuilding();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e()])], 1)], 1)], 1)]), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": _vm.deleteBuilding
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }