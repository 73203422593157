<template>
  <div @click="closePanel" class="user-panel-overlay" id="user-overlay">
    <div class="user-panel">
      <div class="development-panel-header" id="userpanel-header">
        <v-row>
          <h4>{{ building.buildingName ? building.buildingName : building.addressObjects[0].address }}</h4>
        </v-row>
        <v-row style="display: contents">
          <EditButton @clicked="editBuilding(building)" />
        </v-row>
      </div>
      <div class="user-panel-body" id="user-body">
        <div class="main-info-section">
          <h5 class="main-info-title">{{ $t("Subblock.GeneralData") }}</h5>
          <v-row v-if="building.buildingName" class="user-field-row">
            <p class="field-name">{{ $t("PropertyName") }}</p>
            <p class="field-value">{{ building.buildingName }}</p>
          </v-row>
          <v-row v-if="building.addressObjects" class="user-field-row">
            <p class="field-name">{{ $t("BuildingAddress") }}</p>
            <p class="field-value">{{ building.addressObjects[0].address }}</p>
          </v-row>
          <v-row v-if="building.constructionYear" class="user-field-row">
            <p class="field-name">{{ $t("ConstructionYear") }}</p>
            <p class="field-value">
              {{ formatDate(building.constructionYear) }}
            </p>
          </v-row>
          <v-row v-if="building.refurbishmentYear" class="user-field-row">
            <p class="field-name">{{ $t("RefurbishmentYear") }}</p>
            <p class="field-value">
              {{ formatDate(building.refurbishmentYear) }}
            </p>
          </v-row>
          <v-row v-if="building.buildingCostCenter" class="user-field-row">
            <p class="field-name">{{ $t("BuildingCostCenter") }}</p>
            <p class="field-value">
              {{ building.buildingCostCenter }}
            </p>
          </v-row>
          <v-row v-if="building.addOnFactor" class="user-field-row">
            <p class="field-name">{{ $t("AddOnFactor") }}</p>
            <p class="field-value">{{ building.addOnFactor }}</p>
          </v-row>
        </div>
        <div style="margin-top: 32px" class="main-info-section">
          <h5 class="main-info-title">{{ $t("Features") }}</h5>
          <v-row v-if="building.buildingHeatingType" class="user-field-row">
            <p class="field-name">{{ $t("BuildingHeatingType") }}</p>
            <p class="field-value">{{ building.buildingHeatingType }}</p>
          </v-row>
          <v-row v-if="building.coolingType" class="user-field-row">
            <p class="field-name">{{ $t("CoolingType") }}</p>
            <p class="field-value">
              {{ building.coolingType }}
            </p>
          </v-row>
          <v-row v-if="building.buildingVentilationType" class="user-field-row">
            <p class="field-name">{{ $t("BuildingVentilationType") }}</p>
            <p class="field-value">
              {{ formatVentilationType(building.buildingVentilationType) }}
            </p>
          </v-row>
        </div>
        <div style="margin-top: 32px" class="main-info-section">
          <h5 class="main-info-title">{{ $t("Size") }}</h5>
          <v-row v-if="building.netFloorArea" class="user-field-row">
            <p class="field-name">{{ $t("NetFloorArea") }}</p>
            <p class="field-value">{{ building.netFloorArea }} m²</p>
          </v-row>
          <v-row v-if="building.totalArea" class="user-field-row">
            <p class="field-name">{{ $t("TotalArea") }}</p>
            <p class="field-value">
              {{ building.totalArea }} m²
            </p>
          </v-row>
          <v-row v-if="building.floorArea" class="user-field-row">
            <p class="field-name">{{ $t("FloorArea") }}</p>
            <p class="field-value">
              {{ building.floorArea }} m²
            </p>
          </v-row>
          <v-row v-if="building.grossLettableArea" class="user-field-row">
            <p class="field-name">{{ $t("GrossLettableArea") }}</p>
            <p class="field-value">
              {{ building.grossLettableArea }} m²
            </p>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.css";
import moment from "moment";
import EditButton from "@/components/common/EditButton";

export default {
  name: "BuildingDetailPanel",
  props: ["building"],
  components: {
    EditButton,
  },
  data: () => ({
    buildingLoaded: false,
  }),
  created() {
    if (this.building) {
      this.stickyHeader();
    }
  },
  mounted() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        this.closePanel();
        document.onkeydown = null;
      }
    };
    document.documentElement.style.overflow = "hidden";
  },
  beforeDestroy() {
    document.onkeydown = null;
    document.documentElement.style.overflow = "visible";
  },
  computed: {},
  watch: {
    /*  buildingLoaded() {
      this.stickyHeader();
    }, */
  },
  methods: {
    editBuilding(building) {
      this.$emit("editBuilding", building);
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    closePanel(e) {
      if (!e) {
        this.$emit("overlayClicked");
      }
      if (e.target !== e.currentTarget) return;
      this.$emit("overlayClicked");
    },
    async stickyHeader() {
      let headerHeight = null;
      setTimeout(() => {
        headerHeight = document.getElementById("userpanel-header").offsetHeight;
        let marginTop = headerHeight + "px";
        const checkForBodyLoading = setInterval(() => {
          let bodyElement = document.getElementById("user-body");
          if (bodyElement) {
            document.getElementById("user-body").style.marginTop = marginTop;
            clearInterval(checkForBodyLoading);
          }
        }, 50);
      }, 50);
    },

    formatVentilationType(ventilationType) {
      if (ventilationType == "mechanical_in_and_outtake") {
        return this.$t("MechanicalInAndOuttake");
      } else if (ventilationType == "mechanical_air_exhaust") {
        return this.$t("MechanicalAirExhaust");
      } else if (ventilationType == "gravitational") {
        return this.$t("Gravitational");
      } else if (ventilationType == "other") {
        return this.$t("Other");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.user-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  z-index: 7;
  width: 560px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #e6e8ec;
}

.development-panel-header {
  display: flex;
  position: fixed;
  background: white;
  padding: 48px 32px 24px;
  width: 560px;
  border-bottom: 1px solid #e6e8ec;

  h4 {
    max-width: 392px;
    /*overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;*/
  }
}

.user-panel-body {
  padding: 32px 32px 64px;
  overflow: hidden;
}

.main-info {
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}

.user-field-row {
  gap: 24px;
}

.field-name {
  color: #75787a;
  font-family: "Inter", sans-serif;
}

.field-name,
.field-value {
  width: 208px;
  font-size: 14px;
  line-height: 20px;
}

.grey-950 {
  color: #75787a;
}

.user-status-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}
.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  color: white;
  height: 20px;
  max-width: fit-content;
  border-radius: 4px;
  cursor: default;
  white-space: nowrap;
}
.main-info-title {
  margin-bottom: 24px;
}
.profile-picture {
  max-width: 140px;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
}
.re-invite-button {
  cursor: pointer;
}
</style>
