var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "building-tabs-wrapper"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "4px"
    }
  }, _vm._l(_vm.buildings, function (building) {
    return _c('div', {
      key: 'building-' + building._id,
      staticClass: "building-tab",
      class: {
        'selected-building': building === _vm.selectedBuilding
      },
      on: {
        "click": function ($event) {
          return _vm.clickOnBuildingTab(building);
        }
      }
    }, [_vm._v(" " + _vm._s(building.buildingName ? building.buildingName : building.addressObjects[0].address) + " ")]);
  }), 0)]), _vm.unitsLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }) : _vm._e(), _c('table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.unitsLoading,
      expression: "!unitsLoading"
    }],
    key: _vm.tableUpdateKey,
    staticClass: "table-units-container",
    attrs: {
      "id": "table-body"
    }
  }, [_c('thead', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.unitsLoading,
      expression: "!unitsLoading"
    }],
    staticStyle: {
      "display": "table-header-group"
    }
  }, [_c('tr', {
    staticClass: "table-units-title-row"
  }, [_c('th', {
    staticClass: "table-units-row-title label12",
    staticStyle: {
      "max-width": "48px"
    }
  }, [_c('Checkbox', {
    attrs: {
      "checked": _vm.selectedUnits.length !== 0 && _vm.selectedUnits.length === _vm.buildingUnits.length,
      "customStyle": 'width: 16px; height: 16px; margin: 0'
    },
    on: {
      "clicked": function ($event) {
        _vm.selectedUnits.length !== _vm.buildingUnits.length ? _vm.selectedUnits = _vm.buildingUnits.map(function (unit) {
          return unit._id;
        }) : _vm.selectedUnits = [];
      }
    }
  })], 1), _vm._l(_vm.developmentUnitFields, function (unitField) {
    return _c('th', {
      key: unitField.name,
      staticClass: "table-units-row-title label12",
      style: unitField.style,
      attrs: {
        "id": 'unit-' + unitField.className + '-title'
      },
      on: {
        "click": function () {
          return _vm.sortUnitsBy(unitField);
        }
      }
    }, [_c('div', {
      class: {
        'bold-title': unitField.sortingStep !== 0
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(unitField.name)) + " ")]), _c('img', {
      class: {
        'sorting-arrow-up': unitField.sortingStep === 1,
        'sorting-arrow-down': unitField.sortingStep === 2,
        'sorting-arrow-hidden': unitField.sortingStep === 0
      },
      staticStyle: {
        "width": "16px",
        "height": "16px"
      },
      attrs: {
        "src": require("../../assets/images/direction=up.svg"),
        "alt": ""
      }
    })]);
  })], 2)]), !_vm.unitsLoading ? _c('tbody', _vm._l(_vm.buildingUnits, function (unit, index) {
    return _c('tr', {
      key: 'unit-' + unit._id,
      staticClass: "table-units-row"
    }, [!unit.isNewDevelopment ? [_c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "min-width": "48px",
        "max-width": "48px"
      }
    }, [_c('Checkbox', {
      attrs: {
        "checked": _vm.selectedUnits.some(function (un) {
          return un === unit._id;
        }),
        "customStyle": 'width: 16px; height: 16px; margin: 0'
      },
      on: {
        "clicked": function ($event) {
          !_vm.selectedUnits.includes(unit._id) ? _vm.selectedUnits.push(unit._id) : _vm.selectedUnits = _vm.selectedUnits.filter(function (un) {
            return un !== unit._id;
          });
        }
      }
    })], 1), !_vm.openedUnitEdit && _vm.development.assetClass === 'land' ? _c('td', {
      staticClass: "content table-unit-content",
      style: _vm.unitNameSize,
      attrs: {
        "id": 'unit-name-' + index,
        "name": "unitName"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [_c('p', [_vm._v(_vm._s(unit.name ? unit.name : "-"))])]) : _vm._e(), _vm.openedUnitEdit && _vm.development.assetClass === 'land' ? _c('td', {
      style: {
        maxWidth: _vm.developmentUnitFields[1].style.minWidth,
        minWidth: _vm.developmentUnitFields[1].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "name",
        "name": _vm.development.assetClass === 'land' ? 'unitNameLand' : 'unitName'
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: unit.name,
        callback: function ($$v) {
          _vm.$set(unit, "name", $$v);
        },
        expression: "unit.name"
      }
    })], 1) : _vm._e(), _vm.development.assetClass === 'land' ? _c('td', {
      staticClass: "content table-unit-content",
      attrs: {
        "id": 'unit-address-' + index,
        "name": "unitAddress"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [!unit.addressEdit ? _c('p', [_vm._v(" " + _vm._s(unit.address ? unit.address : "-") + " ")]) : _vm._e()]) : _vm._e(), !_vm.openedUnitEdit && _vm.development.assetClass !== 'land' ? _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "id": 'unit-apartmentnumber-' + index,
        "name": "apartmentnumber"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.apartmentNumber ? unit.apartmentNumber : "-") + " ")]) : _vm._e(), _vm.openedUnitEdit && _vm.development.assetClass !== 'land' ? _c('td', {
      style: {
        maxWidth: _vm.developmentUnitFields[1].style.minWidth,
        minWidth: _vm.developmentUnitFields[1].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "apartmentnumber",
        "name": "apartmentnumber"
      }
    }, [_c('v-text-field', {
      ref: "apartmentnumber",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: unit.apartmentNumber,
        callback: function ($$v) {
          _vm.$set(unit, "apartmentNumber", $$v);
        },
        expression: "unit.apartmentNumber"
      }
    })], 1) : _vm._e(), !_vm.openedUnitEdit ? _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "id": 'unit-size-' + index,
        "name": "size"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.generalSurfaceArea ? unit.generalSurfaceArea : "-") + " ")]) : _vm._e(), _vm.openedUnitEdit ? _c('td', {
      style: {
        maxWidth: _vm.developmentUnitFields[3].style.minWidth,
        minWidth: _vm.developmentUnitFields[3].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "size",
        "name": "size"
      }
    }, [_c('v-text-field', {
      key: _vm.valueChanged,
      ref: "unitSize",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        },
        "input": function ($event) {
          return _vm.formatNumberInput($event, 'generalSurfaceArea', index, 'unitSize');
        }
      },
      model: {
        value: unit.generalSurfaceArea,
        callback: function ($$v) {
          _vm.$set(unit, "generalSurfaceArea", $$v);
        },
        expression: "unit.generalSurfaceArea"
      }
    })], 1) : _vm._e(), !_vm.openedUnitEdit && _vm.development.assetClass !== 'land' ? _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "id": 'unit-floor-' + index,
        "name": "floor"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.floor ? unit.floor : "-") + " ")]) : _vm._e(), _vm.openedUnitEdit && _vm.development.assetClass !== 'land' ? _c('td', {
      style: {
        maxWidth: _vm.developmentUnitFields[1].style.minWidth,
        minWidth: _vm.developmentUnitFields[1].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "floor",
        "name": "floor"
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: unit.floor,
        callback: function ($$v) {
          _vm.$set(unit, "floor", $$v);
        },
        expression: "unit.floor"
      }
    })], 1) : _vm._e(), !_vm.openedUnitEdit && _vm.development.assetClass !== 'land' ? _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "id": 'unit-rooms-' + index,
        "name": "rooms"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.rooms ? unit.rooms : "-") + " ")]) : _vm._e(), _vm.openedUnitEdit && _vm.development.assetClass !== 'land' ? _c('td', {
      style: {
        maxWidth: _vm.developmentUnitFields[2].style.minWidth,
        minWidth: _vm.developmentUnitFields[2].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "rooms",
        "name": "rooms"
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: unit.rooms,
        callback: function ($$v) {
          _vm.$set(unit, "rooms", $$v);
        },
        expression: "unit.rooms"
      }
    })], 1) : _vm._e(), !_vm.openedUnitEdit && _vm.development.assetClass !== 'land' ? _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "cursor": "pointer",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
      },
      attrs: {
        "id": 'unit-storeroom-' + index,
        "name": "storeroom"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [_c('p', [_vm._v(_vm._s(unit.storeRoomNr ? unit.storeRoomNr : "-"))])]) : _vm._e(), _vm.openedUnitEdit && _vm.development.assetClass !== 'land' ? _c('td', {
      style: {
        maxWidth: _vm.developmentUnitFields[3].style.minWidth,
        minWidth: _vm.developmentUnitFields[3].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "storeroom",
        "name": "storeroom"
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: unit.storeRoomNr,
        callback: function ($$v) {
          _vm.$set(unit, "storeRoomNr", $$v);
        },
        expression: "unit.storeRoomNr"
      }
    })], 1) : _vm._e(), !_vm.openedUnitEdit && _vm.development.assetClass !== 'land' ? _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "cursor": "pointer",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
      },
      attrs: {
        "id": 'unit-parkingspot-' + index,
        "name": "parkingspot"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [_c('p', [_vm._v(_vm._s(unit.parkingSpotNr ? unit.parkingSpotNr : "-"))])]) : _vm._e(), _vm.openedUnitEdit && _vm.development.assetClass !== 'land' ? _c('td', {
      style: {
        maxWidth: _vm.developmentUnitFields[4].style.minWidth,
        minWidth: _vm.developmentUnitFields[4].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "parkingspot",
        "name": "parkingspot"
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: unit.parkingSpotNr,
        callback: function ($$v) {
          _vm.$set(unit, "parkingSpotNr", $$v);
        },
        expression: "unit.parkingSpotNr"
      }
    })], 1) : _vm._e(), !_vm.openedUnitEdit ? _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "id": 'unit-amount-' + index,
        "name": "amount"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.amount ? _vm.$nMapper.price(unit.amount) : "-") + " ")]) : _vm._e(), _vm.openedUnitEdit ? _c('td', {
      style: {
        maxWidth: _vm.developmentUnitFields[4].style.minWidth,
        minWidth: _vm.developmentUnitFields[4].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "amount",
        "name": "amount"
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: unit.amount,
        callback: function ($$v) {
          _vm.$set(unit, "amount", $$v);
        },
        expression: "unit.amount"
      }
    })], 1) : _vm._e(), _c('td', {
      staticClass: "content table-unit-content",
      attrs: {
        "name": "sqm",
        "id": "sqm"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.pricePerSquareMeter ? unit.pricePerSquareMeter : "-") + " ")]), _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "display": "flex",
        "align-items": "center"
      },
      attrs: {
        "name": "leads",
        "id": 'unit-leads-' + index
      }
    }, [_c('div', {
      staticClass: "listing-title-row",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.collapseBoard(index, unit.listing);
        }
      }
    }, [_c('div', {
      staticClass: "button-leads",
      attrs: {
        "id": index + 'button-container'
      }
    }, [_c('img', {
      staticStyle: {
        "height": "13px",
        "width": "13px"
      },
      attrs: {
        "id": index + 'down',
        "src": require("../../assets/images/collapse-down.svg"),
        "alt": ""
      }
    }), _c('img', {
      staticStyle: {
        "display": "none",
        "height": "13px",
        "width": "13px"
      },
      attrs: {
        "id": index + 'up',
        "src": require("../../assets/images/collapse-up.svg"),
        "alt": ""
      }
    }), _c('p', {
      key: _vm.leadCounterUpdater,
      staticClass: "leads-number-text",
      class: {
        'leads-open-text': _vm.openedLeads.includes(unit.listing._id),
        'leads-closed-text': !_vm.openedLeads.includes(unit.listing._id)
      },
      attrs: {
        "id": index + 'leadsnum'
      }
    }, [_vm._v(" " + _vm._s(_vm.listingLeads[unit.listing._id] === 1 ? _vm.listingLeads[unit.listing._id] + ` ${_vm.$t("Lead")}` : _vm.listingLeads[unit.listing._id] + ` ${_vm.$t("NumerableLeads")}`) + " ")])])])]), unit.status ? _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "display": "flex",
        "align-items": "center"
      },
      attrs: {
        "name": "status",
        "id": 'unit-status-' + index
      }
    }, [_c('v-row', {
      staticClass: "listing-status-button-active"
    }, [_c('p', {
      staticClass: "statusLabel content-bold",
      class: {
        'active-label ': unit.status === 'free',
        'booked-label': unit.status === 'booked',
        'sold-label ': unit.status === 'sold' || unit.status === 'rented'
      },
      attrs: {
        "id": 'statusLabel' + index
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.openStatusDropdown(unit, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.status(unit.status)) + " ")]), _c('v-tooltip', {
      key: _vm.bookingUpdater,
      attrs: {
        "bottom": "",
        "right": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [unit.status === 'booked' ? _c('p', _vm._g(_vm._b({
            staticClass: "booked-label booked-info-label"
          }, 'p', attrs, false), on), [_c('img', {
            staticStyle: {
              "filter": "invert(1)"
            },
            attrs: {
              "src": require("../../assets/images/info.svg"),
              "alt": ""
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.openBookingDetailsModal(unit);
              }
            }
          })]) : _vm._e()];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("BookingDetails")) + " ")])])], 1)], 1) : _vm._e(), !unit.status ? _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "display": "flex",
        "align-items": "center"
      },
      attrs: {
        "id": 'unit-status-' + index,
        "name": "status"
      }
    }, [_vm._v(" - ")]) : _vm._e(), !unit.bookedUntil ? _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "display": "flex",
        "align-items": "center"
      },
      attrs: {
        "id": 'unit-status-' + index,
        "name": "bookedUntil"
      }
    }, [_vm._v(" - ")]) : _vm._e(), unit.bookedUntil ? _c('td', {
      staticClass: "content table-unit-content",
      attrs: {
        "id": "bookedUntil",
        "name": "bookedUntil"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatDate(unit.bookedUntil)) + " ")]) : _vm._e(), _c('td', {
      staticClass: "content table-unit-content",
      attrs: {
        "name": "isPublic",
        "id": "isPublic"
      }
    }, [_c('BaseToggle', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.development.status !== 'archived',
        expression: "development.status !== 'archived'"
      }],
      attrs: {
        "value": unit.listing.isPublic
      },
      on: {
        "change": function (val) {
          return _vm.toggleListingPublic(val, unit.listing._id);
        }
      }
    })], 1), _c('td', {
      staticClass: "content table-unit-content",
      attrs: {
        "id": "unitDetails",
        "name": "unitDetails"
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('unitClicked', unit);
        }
      }
    }, [_c('p', {
      staticClass: "link"
    }, [_vm._v(_vm._s(_vm.$t("UnitDetails")))])]), _vm.development.assetType !== 'LeaseOut' ? _c('td', {
      staticClass: "content table-unit-content",
      attrs: {
        "id": "lawOfObligations",
        "name": "lawOfObligations"
      }
    }, [_c('date-pick', {
      attrs: {
        "format": 'DD.MM.YYYY'
      },
      on: {
        "input": function ($event) {
          return _vm.updateUnit(unit);
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref2) {
          var toggle = _ref2.toggle;
          return [_c('div', {
            staticStyle: {
              "width": "100%",
              "height": "100%"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return toggle.apply(null, arguments);
              }
            }
          }, [!unit.lawOfObligations ? _c('p', {
            staticClass: "content-normal"
          }, [_vm._v(" - ")]) : _vm._e(), unit.lawOfObligations ? _c('p', {
            staticClass: "content-normal"
          }, [_vm._v(" " + _vm._s(unit.lawOfObligations) + " ")]) : _vm._e()])];
        }
      }], null, true),
      model: {
        value: unit.lawOfObligations,
        callback: function ($$v) {
          _vm.$set(unit, "lawOfObligations", $$v);
        },
        expression: "unit.lawOfObligations"
      }
    })], 1) : _vm._e(), _vm.development.assetType !== 'LeaseOut' ? _c('td', {
      staticClass: "content table-unit-content",
      attrs: {
        "id": "lawOfProperty",
        "name": "lawOfProperty"
      }
    }, [_c('date-pick', {
      attrs: {
        "format": 'DD.MM.YYYY'
      },
      on: {
        "input": function ($event) {
          return _vm.updateUnit(unit);
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref3) {
          var toggle = _ref3.toggle;
          return [_c('div', {
            staticStyle: {
              "width": "100%",
              "height": "100%"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return toggle.apply(null, arguments);
              }
            }
          }, [!unit.lawOfProperty ? _c('p', {
            staticClass: "content-normal"
          }, [_vm._v("-")]) : _vm._e(), unit.lawOfProperty ? _c('p', {
            staticClass: "content-normal"
          }, [_vm._v(" " + _vm._s(unit.lawOfProperty) + " ")]) : _vm._e()])];
        }
      }], null, true),
      model: {
        value: unit.lawOfProperty,
        callback: function ($$v) {
          _vm.$set(unit, "lawOfProperty", $$v);
        },
        expression: "unit.lawOfProperty"
      }
    })], 1) : _vm._e(), !_vm.openedUnitEdit ? _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "id": 'unit-amount_paid-' + index,
        "name": "amount_paid"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.amount_paid ? _vm.$nMapper.price(unit.amount_paid) : "-") + " ")]) : _vm._e(), _vm.openedUnitEdit ? _c('td', {
      style: {
        maxWidth: _vm.developmentUnitFields[4].style.minWidth,
        minWidth: _vm.developmentUnitFields[4].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "amount_paid",
        "name": "amount_paid"
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: unit.amount_paid,
        callback: function ($$v) {
          _vm.$set(unit, "amount_paid", $$v);
        },
        expression: "unit.amount_paid"
      }
    })], 1) : _vm._e(), _c('td', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "id": 'unit-amount_unpaid-' + index,
        "name": "amount_unpaid"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleListingPanel(unit.listing);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.amount_unpaid ? _vm.$nMapper.price(unit.amount_unpaid) : "-") + " ")])] : _vm._e(), unit.isNewDevelopment ? _c('td', {
      staticClass: "kanban-table-row"
    }, [_c('LeadsKanban', {
      staticStyle: {
        "cursor": "auto",
        "width": "100%"
      },
      attrs: {
        "kanban-updater": _vm.kanbanUpdater,
        "listing": unit
      },
      on: {
        "leadOpened": _vm.openKanbanLead,
        "funnelCount": function ($event) {
          return _vm.setNumberOfLeads($event);
        },
        "refreshLeads": _vm.refreshBoardLeads
      }
    })], 1) : _vm._e()], 2);
  }), 0) : _vm._e(), _vm.buildingUnits.length === 0 && !_vm.unitsLoading ? _c('div', {
    staticClass: "content no-units"
  }, [_vm._v(" " + _vm._s(_vm.development.assetClass === "land" ? _vm.$t("AddPlotToStart") : _vm.$t("AddUnitToStart")) + " ")]) : _vm._e()]), _vm.statusesOpen ? _c('StatusDropdown', {
    attrs: {
      "units-view": true,
      "position": _vm.position,
      "unit": _vm.selectedUnit,
      "developmentId": _vm.development._id,
      "buildingId": _vm.selectedBuilding._id,
      "multi": false
    },
    on: {
      "closed": function ($event) {
        _vm.statusesOpen = false;
      },
      "statusChanged": _vm.changeUnitStatus,
      "open-booking-modal": function ($event) {
        return _vm.openBookingDetailsModal(_vm.selectedUnit);
      }
    }
  }) : _vm._e(), _vm.bookingDetailsModalOpen ? _c('BookingDetailsModal', {
    attrs: {
      "opened-listing": _vm.selectedUnit.listing,
      "unit": _vm.selectedUnit
    },
    on: {
      "statusChanged": _vm.changeUnitStatus,
      "close-modal": function ($event) {
        _vm.bookingDetailsModalOpen = false;
      }
    }
  }) : _vm._e(), _vm.unitOpened ? _c('UnitSidepanel', {
    attrs: {
      "unit-id": _vm.clickedUnit._id
    },
    on: {
      "closed": _vm.toggleUnitPanel,
      "refresh": function ($event) {
        return _vm.$emit('refresh');
      }
    }
  }) : _vm._e(), _vm.listingPanelOpen ? _c('ListingSidepanel', {
    attrs: {
      "listing-id": _vm.clickedListing._id,
      "development": _vm.development
    },
    on: {
      "overlayClicked": function ($event) {
        _vm.listingPanelOpen = false;
      }
    }
  }) : _vm._e(), _vm.leadOpen ? _c('ClientSidepanel', {
    attrs: {
      "customer-data": _vm.clickedLead.leadId,
      "from-project-general": true
    },
    on: {
      "panelClosed": function ($event) {
        _vm.leadOpen = false;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }